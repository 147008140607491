<template>
  <MainLayout>
    <template v-slot:default>
      <TableFilters :queries="queries" :loading="isDateLoaded">
        <template v-slot:filter-title>
          <span v-if="sourceObject.count">
            <span class="title-filter">Всего:</span>{{ sourceObject.count }}
          </span>
        </template>
        <template v-slot:download-button>
          <v-btn
            @click="downloadList"
            class="btn btn_color_grey btn-unload flex-right mr-4 "
            :disabled="loadingBtn || sourceObject.results.length == 0"
            :loading="loadingBtn"
          >
            Выгрузить
          </v-btn>
        </template>
      </TableFilters>
      <TableLayout
        :loading="isDateLoaded"
        :table-data="sourceObject.results"
        :header="header"
        :source-object="sourceObject"
      />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsUsers from "@/helpers/tableCols/User";
export default {
  name: "UsersPage",
  data() {
    return {
      loadingBtn: false,
      queries: query.users.filter(
        el => el.userRole.includes(this.$store.getters.getCurrentRole) // && el.type !== "groups" //497
      )
    };
  },
  components: { TableFilters, TableLayout, MainLayout },
  computed: {
    sourceObject() {
      return this.$store.getters.getPageUsersList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    },
    role() {
      return this.$store.getters.getCurrentRole;
    },
    toPath() {
      return "users";
    },
    header() {
      let preHeader = tableColsUsers
        .filter(el => {
          return el.userRole.includes(this.$store.getters.getCurrentRole);
        })
        .filter(
          el =>
            ![
              "app_paid_until",
              "card_code",
              "access_groups",
              "user",
              "comment"
            ].includes(el.value) ||
            [31, 21].includes(this.$store.getters.getCurrentRole)
        );
      return preHeader;
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store.dispatch("downloadPageUsersList", this.header).then(() => {
        this.loadingBtn = false;
      });
    }
  },
  created() {
    localStorage.removeItem("ObjectId");
    localStorage.removeItem("KindergartenId");

    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: {
        ...this.urlQuery.query
        // type: this.type
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageUsersList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query
          // type: this.type
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageUsersList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearUsersPage").then(() => next());
  }
};
</script>

<style scoped></style>
